table{
    border-collapse:collapse;
}
table tr{
    background-color:#fff;
}
table th{
    position:sticky;
    top:-1rem;
    background-color:#fff;
    padding:1rem 2rem;
    border-bottom:1px solid #e3e3e3;
}
table td{
    position:relative;
    background-color:#fff;
    padding:1rem 2rem;
    border-bottom:1px solid #e3e3e3;
    font-size:0.7em;
}

.shippings-table .big-table{
    display:flex;
}
.shippings-table .big-table .stores-list{
    flex:1;
}

.table-btn{
    border:none;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:#fff;
}

.table-btn.black{
    background-color:#333;
    color:#fff;
}

.table-btn.primary{
    background-color:#8ec748;
    color:#fff;
}

table .product-title{
    display:flex;
    flex-wrap:wrap;
}

table .product-title span{
    width:100%;
    font-size:0.8em;
}