.tabs-buttons{
    list-style:none;
    margin:0;
    padding:0;
    width:100%;
}

.tabs-buttons li{
    display:inline-block;
    width:50%;
}

.tabs-buttons li button{
    margin:0;
    padding:.7rem;
    font-size:1em;
    width:100%;
    border:none;
    background-color:rgba(01,102,72,0.5);
    color:#fff;
    font-weight:bold;
    cursor: pointer;
}

.tabs-buttons li.active button{
    background-color:#016648;
    color:#fff;
}

.tab-item{
    display:none;
}

.tab-item.active{
    display:block;
}