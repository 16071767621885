.theme-box{
    margin:0;
    background-color:#eee;
    height:100vh;
    display:flex;
    flex-direction:column;
}

header{
    margin:0;
    background-color:#8ec748;
    display:flex;
}
header .left{
    flex:1;
    margin:auto 0;
}

header img{
    height:2.7rem;
}
header .btn, .navbar .btn{
    border-radius:0;
}

.theme-body{
    flex:1;
    display:flex;
    font-family:sans-serif;
    overflow:auto;
}

.navbar{
    background-color:#000;
    display:none;
}
.navbar.active{
    
    display:flex;
}
.navbar ul{
    margin:0;
    padding:0;
    list-style:none;
}

.navbar .submenu{
    background-color:#333;
    width:200px;
}
.navbar .submenu a{
    display:block;
    padding:1rem;
    color:#fff;
    text-decoration:none;
    font-family:sans-serif;
}
.navbar .submenu a:hover, .navbar .submenu a.active{
    background-color:#016648;
    color:#fff;
}

.container{
    width:100%;
    padding:1rem;
    overflow:auto;
}