.data-selector{
    display:flex;
    align-items:center;
}
.data-selector label{
    margin-right:10px;
    width:50%;
}
input,select{
    width:100%;
}

.products-list{
    display:flex;
    flex-wrap:wrap;
    margin:10px auto;
    justify-content:space-between;
}
.products-list .product-item{
    width:100%;
    max-width:350px;
    display:flex;
    padding:5px;
    background-color:#fff;
    margin:5px;
}
.products-list .product-item .desc{
    flex:1;
}
.products-list .product-item .desc small{
    font-size:.5em;
}
.products-list .product-item .desc p{
    margin:0;
    padding:0;
    font-size:.7em;
}
.products-list .product-item .amount{
    width:100px;
}
.products-list .product-item .amount input{
    padding:5px;
    text-align:right;
}