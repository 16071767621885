.access-box{
    margin:0;
    padding:0;
    width:100%;
    height:100vh;
    background-color:#000;
    display:flex;
}

.access-card{
    margin:auto;
    padding:1rem;
}